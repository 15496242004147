import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faTwitter,
    faLinkedin,
    faGetPocket,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    PocketShareButton,
    WhatsappShareButton,
} from 'react-share';
import { useMediaQuery } from 'react-responsive';

import '../../styles/article.scss';

const ArticleShareButton = ({ url, title, twitterHandle }) => {
    let emailSubject = 'Sharing Article: ' + title;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    let iconSize = isTabletOrMobile ? '1x' : '2x';
    return (
        <div className="article-share">
            <div className="article-share-label">Share it on:</div>
            <div className="article-share-social">
                <FacebookShareButton url={url} title={title}>
                    <FontAwesomeIcon className="facebook" icon={faFacebook} size={iconSize} />
                </FacebookShareButton>

                <TwitterShareButton url={url} title={title} via={twitterHandle}>
                    <FontAwesomeIcon className="twitter" icon={faTwitter} size={iconSize} />
                </TwitterShareButton>

                <LinkedinShareButton url={url} title={title}>
                    <FontAwesomeIcon className="linkedin" icon={faLinkedin} size={iconSize} />
                </LinkedinShareButton>

                <EmailShareButton url={url} body="Check out this article: " subject={emailSubject}>
                    <FontAwesomeIcon className="email" icon={faEnvelopeOpenText} size={iconSize} />
                </EmailShareButton>

                <PocketShareButton url={url}>
                    <FontAwesomeIcon className="pocket" icon={faGetPocket} size={iconSize} />
                </PocketShareButton>

                <WhatsappShareButton url={url} title={title}>
                    <FontAwesomeIcon className="whatsapp" icon={faWhatsapp} size={iconSize} />
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default ArticleShareButton;
