import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ArticleTitle from '../components/Article/ArticleTitle';
import ArticleAuthor from '../components/Article/ArticleAuthor';
import ArticleImage from '../components/Article/ArticleImage';
import ArticleAppLink from '../components/Article/ArticleAppLink';
import ArticleShareButton from '../components/Article/ArticleShareButton';
import { encodeArticleURL } from '../utils/link-utils';

import '../styles/global.scss';

const ArticleTemplate = ({ pageContext: { article } }) => {
    const url =
        typeof window !== 'undefined'
            ? window.location.href
            : `https://www.earthhero.org${encodeArticleURL(article.slug)}`;
    const twitterHandle = `EarthHeroOrg`;

    return (
        <Layout>
            <SEO
                title={article.title}
                url={url}
                imageUrl={article.image}
                author={article.author}
                pageType="article"
            />
            <section className="section">
                <div className="container articleContent">
                    <div className="textBlock">
                        <ArticleTitle>{article.title}</ArticleTitle>
                        <ArticleShareButton
                            url={url}
                            title={article.title}
                            twitterHandle={twitterHandle}
                        />
                        <ArticleAuthor>{article.author}</ArticleAuthor>
                        <ArticleImage article={article} />
                        <ReactMarkdown children={article.content} remarkPlugins={[remarkGfm]} />
                        <hr />
                        <ArticleAppLink
                            photoCreditFormatted={article['Photo Credit (formatted)']}
                        />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ArticleTemplate;
