import React from 'react';

const ArticleImage = ({ article }) => {
    return (
        <>
            <img src={article.image} className="articleImage" alt="" />
        </>
    );
};

export default ArticleImage;
