import React from 'react';
import { motion } from 'framer-motion';

import '../../styles/article.scss';

const ArticleTitle = ({ children }) => (
    <motion.h1
        initial={{
            opacity: 0,
            scale: 0.8,
            y: 20,
        }}
        animate={{
            scale: 1,
            opacity: 1,
            y: 0,
        }}
        transition={{
            duration: 1,
        }}
    >
        <div className="pageHeadlineLeft">{children}</div>
    </motion.h1>
);

export default ArticleTitle;
