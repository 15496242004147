import React from 'react';
import { motion } from 'framer-motion';
import { convertToHtml } from '../../utils/html-string-utils';

import DownloadAppButtons from '../DownloadAppButtons';

import '../../styles/global.scss';

const ArticleAppLink = ({ photoCreditFormatted }) => {
    return (
        <div>
            <motion.div
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                    delay: 0.2,
                }}
                className="pageHeroSubhead"
            >
                Join the movement. <br />
                Download Earth Hero now to collectively address the climate crisis
            </motion.div>
            <motion.div
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                    delay: 0.4,
                }}
                className="app__downloads"
            >
                <DownloadAppButtons />
            </motion.div>
            {photoCreditFormatted && (
                <>
                    <br />
                    <h6 className="photo-credit-heading">Photo Credit</h6>
                    <div
                        className="photo-credit-text"
                        dangerouslySetInnerHTML={{
                            __html: convertToHtml(photoCreditFormatted),
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default ArticleAppLink;
